import { type Routes } from '@angular/router';
import { NullComponent } from '@core/components/null/null.component';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { RestrictedAccessComponent } from '@core/components/restricted-access/restricted-access.component';
import { UnderConstructionComponent } from '@core/components/under-constraction/under-construction.component';
import { adminGuardFn } from '@core/guards/admin.guard';
import { authGuardFn } from '@core/guards/auth.guard';
import { dashboardGuard } from '@core/guards/dashboard.guard';
import { findUrlGuardFn } from '@core/guards/find-url.guard';
import { navigationWizardGuardFn } from '@core/guards/navigation-wizard.guard';
import { HOME_PAGE, NULL_PAGE } from '@shared/constants/ume-constants';

import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: NullComponent,
    pathMatch: 'full',
    canActivate: [authGuardFn, findUrlGuardFn],
  },
  {
    path: NULL_PAGE, // Log out intermediate step
    component: NullComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./routes/auth/auth-routing').then((m) => m.AUTH_ROUTES),
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/auth/auth-routing').then((m) => m.AUTH_ROUTES),
  },
  {
    path: 'signup',
    redirectTo: 'auth/signup',
  },
  // BaseLayoutComponent container for app
  {
    path: '',
    canActivate: [authGuardFn],
    component: BaseLayoutComponent,
    children: [
      {
        path: HOME_PAGE,
        canActivate: [navigationWizardGuardFn, dashboardGuard],
        loadChildren: () =>
          import('./routes/dashboard/dashboard-routing').then((m) => m.DASHBOARD_ROUTES),
      },
      {
        path: 'search',
        loadChildren: () => import('./routes/search/search-routing').then((m) => m.SEARCH_ROUTES),
      },
      {
        path: 'my-profile',
        loadChildren: () =>
          import('./routes/my-profile/my-profile-routing').then((m) => m.MY_PROFILE_ROUTES),
      },
      {
        path: 'questionnaire',
        loadChildren: () => import('./routes/ddq/ddq-routing').then((m) => m.DDQ_ROUTES),
      },
      {
        path: 'company',
        canActivate: [navigationWizardGuardFn],
        loadChildren: () =>
          import('./routes/counterparties/counterparties-routing').then(
            (m) => m.CONTERPARTIES_ROUTES
          ),
      },
      {
        path: 'user',
        canActivate: [navigationWizardGuardFn],
        loadChildren: () => import('./routes/user/user-routing').then((m) => m.USER_ROUTES),
      },
      {
        path: 'scoring',
        loadChildren: () =>
          import('./routes/scoring/scoring-routing').then((m) => m.SCORING_ROUTES),
      },
      {
        path: 'admin',
        loadChildren: () => import('./routes/admin/admin-routing').then((m) => m.ADMIN_ROUTES),
      },
      {
        path: 'plans',
        loadChildren: () =>
          import('./routes/upgrade-plan/upgrade-plan-routing').then((m) => m.UPGRADE_PLAN),
      },
      {
        path: 'about',
        loadChildren: () => import('./routes/about/about-routes').then((r) => r.ABOUT_ROUTES),
      },
      {
        path: 'provider',
        loadChildren: () =>
          import('./routes/provider/provider-routing').then((m) => m.PROVIDER_ROUTES),
        canActivate: [adminGuardFn],
      },
      {
        path: 'managed-services',
        loadChildren: () =>
          import('./routes/provider/modules/managed-services/managed-services-routing').then(
            (m) => m.WORK_ORDERS_ROUTES
          ),
        canActivate: [adminGuardFn],
      },
      {
        path: 'my-account',
        loadChildren: () =>
          import('./routes/provider/components/services-page/routes/services-routes').then(
            (mod) => mod.COPANY_SERVICES_ROUTES
          ),
      },
      {
        path: 'restricted-user-rights',
        component: RestrictedAccessComponent,
      },
      {
        path: 'under-construction',
        component: UnderConstructionComponent,
      },
      {
        path: 'distributor/policy-file/edit', // need to be changed in email
        redirectTo: 'my-profile/policy-files',
      },
      {
        path: 'policy-files/edit', // need to be changed in email
        redirectTo: 'my-profile/policy-files',
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [authGuardFn],
    pathMatch: 'full',
  },
];
