<div class="dist-menu">
  @if (!showMatMenu) {
    <ng-container [ngTemplateOutlet]="navListTemplate"></ng-container>
  } @else {
    <ng-container [ngTemplateOutlet]="matMenuTemplate"></ng-container>
  }
</div>

<ng-template #navListTemplate>
  @if (items().length) {
    <ul class="app-menu-ul">
      @for (item of items(); track item.label) {
        <li class="item">
          @if (item.subItems && item.subItems.length === 1) {
            @if (!item.subItems[0].customEvent) {
              <a
                #rla="routerLinkActive"
                mat-button
                [routerLink]="item.subItems[0].link | companyTypeBasedRoute"
                routerLinkActive="link_active"
                [ngClass]="premiumLabel === item.subItems[0].label ? 'premium-menu-item' : ''"
                [target]="item.subItems[0].target || '_self'"
                (click)="handleClick(item.subItems[0], $event)"
                class="link">
                <span class="menu-label">{{ item.subItems[0].name }}</span>
              </a>
            }

            @if (item.subItems[0].customEvent) {
              <button
                mat-menu-item
                class="link"
                (click)="handleClick(item.subItems[0], $event)">
                <span class="menu-label">{{ item.subItems[0].name }}</span>
              </button>
            }
          }
          @if (item.subItems && item.subItems.length > 1) {
            <button
              mat-button
              [matMenuTriggerFor]="submenu.menu"
              class="link">
              <span class="inner-button-container">
                <span class="menu-label">{{ item.name }}</span>
                <mat-icon>expand_more</mat-icon>
              </span>
            </button>
            <ume-main-nav-menu-panel
              #submenu
              [items]="item.subItems"></ume-main-nav-menu-panel>
          }
        </li>
      }
    </ul>
  }
</ng-template>

<ng-template #matMenuTemplate>
  <div class="trigger-content">
    <button
      mat-button
      [matMenuTriggerFor]="menu">
      <mat-icon class="mat-18">menu</mat-icon>
      Menu
    </button>
  </div>

  <mat-menu
    #menu="matMenu"
    role="menu">
    @if (items().length) {
      @for (item of items(); track item.label) {
        @if (item.subItems && item.subItems.length === 1) {
          @if (!item.subItems[0].customEvent) {
            <a
              mat-menu-item
              [target]="item.subItems[0].target || '_self'"
              routerLinkActive="link_active"
              class="link"
              [ngClass]="premiumLabel === item.subItems[0].label ? 'premium-menu-item' : ''"
              [routerLink]="item.subItems[0].link | companyTypeBasedRoute"
              (click)="handleClick(item.subItems[0], $event)">
              <span class="menu-label text-capitalize">{{ item.subItems[0].name }}</span>
            </a>
          }

          @if (item.subItems[0].customEvent) {
            <button
              mat-menu-item
              class="link"
              (click)="handleClick(item.subItems[0], $event)">
              <span class="menu-label">{{ item.subItems[0].name }}</span>
            </button>
          }
        }

        @if (item.subItems && item.subItems.length > 1) {
          <button
            mat-menu-item
            [matMenuTriggerFor]="submenu.menu"
            class="link text-capitalize">
            <span class="menu-label text-capitalize">{{ item.name }}</span>
          </button>
          <ume-main-nav-menu-panel
            #submenu
            [items]="item.subItems"></ume-main-nav-menu-panel>
        }
      }
    }
  </mat-menu>
</ng-template>
