<div
  id="app-wrapper"
  class="base-content-grid">
  <div
    id="page-loader"
    class="base-full-width">
    @if (loading$ | async) {
      <mat-progress-bar
        mode="indeterminate"
        class="breakout"></mat-progress-bar>
    }
  </div>

  @let notifications = notifications$ | async;

  @if ($isLoggedIn | async) {
    <ume-main-nav-wrap
      id="ume-top"
      class="base-full-width">
      <ume-navigation [items]="navigationItems"></ume-navigation>
      <nav class="menu-right">
        @if (notUmeOps) {
          <button
            mat-button
            matTooltip="Help"
            [matMenuTriggerFor]="aboutMenu">
            Help
          </button>

          @defer (on idle) {
            @if (notifications && notifications?.entryBeans?.length) {
              <button
                mat-icon-button
                matTooltip="Notifications"
                class="notification-btn"
                [matMenuTriggerFor]="notificationsList">
                <mat-icon>notifications</mat-icon>
                <span class="sign"></span>
              </button>

              <mat-menu
                #notificationsList="matMenu"
                xPosition="before">
                <ume-user-notifications
                  [notifications]="notifications"
                  [canConnect]="notifications.canConnect"
                  [isMenu]="true"
                  [isVirtualPremium]="isVirtualPremium"></ume-user-notifications>
              </mat-menu>
            }
          }
        }

        <ume-profile-menu></ume-profile-menu>
      </nav>
    </ume-main-nav-wrap>

    <main id="ume-content">
      <router-outlet></router-outlet>
    </main>
  } @else {
    <router-outlet></router-outlet>
  }
</div>

<mat-menu
  #aboutMenu="matMenu"
  xPosition="before">
  @for (item of helpItems; track item.label) {
    @if (item.external) {
      <a
        mat-menu-item
        [target]="item.target || '_blank'"
        [href]="item.link">
        <mat-icon>{{ item.icon }}</mat-icon>
        <span>{{ item.name }}</span>
      </a>
    } @else {
      <a
        mat-menu-item
        target="_self"
        [routerLink]="item.link | companyTypeBasedRoute">
        <mat-icon>{{ item.icon }}</mat-icon>
        <span>{{ item.name }}</span>
      </a>
    }
  }

  <button
    mat-menu-item
    (click)="welcomeGuide()">
    Welcome guide
  </button>
</mat-menu>
